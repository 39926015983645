
export const COLOR = {
  blue: "#4169e1",
  transparentWhite30: "rgba(255,255,255,0.3)",
  transparentWhite50: "rgba(255,255,255,0.5)",
  transparentWhite80: "rgba(255,255,255,0.8)",
}


export const TimeControllerItemHeight = 50

export const ContentWidthDesktop = '600px'
